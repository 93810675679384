.filter-container {
  background-color: transparent;
  .filter-content {
    background: #fff;
    padding: 20px;
    border-radius: 4px;
  }

  button {
    width: 100%;
  }

  .footer-buttons {
    display: flex;
    button {
      margin: 5px;
    }
  }
}
