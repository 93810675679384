.open-sidebar .sidenav-brand {
  max-width: 255px;
  margin: 0 auto;
  padding: 15px;
  transition: ease all 300ms;
}
.sidenav-brand {
  margin: 0 0 0 40px;
  padding: 15px 0 15px 15px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  max-width: 150px;
  transition: ease all 300ms;
}
