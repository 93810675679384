@import 'fonts';
@import 'bootstrap';
@import 'variables';

html,
body,
.App,
#root {
  height: 100%;
  display: flex;
  width: 100%;
  box-sizing: border-box;
}
* {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}
.control-section {
  width: 100%;
  -webkit-transition: background-image 0.2s ease-in-out;
  transition: background-image 0.2s ease-in-out;
  height: 100%;
}
#sidebar-section {
  height: 100%;
}
#wrapper {
  height: 100%;
}
.content-container {
  height: 100%;
}
.filter-container {
  height: 100%;
  display: flex;
  align-items: center;
}
#main-content {
  height: 100%;
}
button {
  background-color: $button-default;
  border: none;
  border-radius: 4px;
  padding: 6px 15px;
  color: #fff;
  display: block;
  text-transform: uppercase;
  font-weight: 600;
}
body .MuiPopover-paper {
  overflow: visible;
}
li.MuiMenuItem-root {
  font-size: 11px;
  padding-top: 4px;
  padding-bottom: 4px;
  overflow: visible;
  &:hover > .subitem {
    opacity: 1;
    visibility: visible;
  }
}
body .primary-btn {
  background-color: #0470df;
  color: #fff;
}
.group-field {
  .MuiCircularProgress-root {
    position: absolute;
    top: 35px;
    right: 50px;
  }
}
.dashboard-container {
  display: block;
  height: 100%;
  max-height: calc(100vh - 275px);
  overflow: auto;
  padding: 0 15px 0 0;
}
p.MuiFormHelperText-root.Mui-error {
  display: none;
}
.lancamentos .control-section {
  height: auto;
}

button[data-test='sign-out-button'] {
  display: none;
}
a[data-test='require-new-password-back-to-sign-in-link'] {
  margin-top: 25px;
  display: block;
  text-align: center;
  cursor: pointer;
  transition: ease all 400ms;
  &:hover {
    color: #0370df;
  }
}
