.file-icon-button {
  position: relative;
}

.file-icon {
  font-size: 35px;
  color: #fe0000;
  position: relative;
}

.file-icon:hover {
  color: rgb(253, 97, 97);
}

.file-icon-tooltip {
  z-index: 1;
  padding: 5px 0;
  width: 100px;
  position: absolute !important;
  top: -100%;
  left: -60%;
  visibility: hidden;
  background-color: black;
  color: white;
  font-size: 12px;
  text-align: center;
  border-radius: 6px;
}
.maquinas-table .lineRow:last-child .cel {
  font-size: 1em;
  font-weight: 600;
}

.file-icon-tooltip:after {
  content: '';
  position: absolute;
  top: 100%;
  left: 60%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.file-icon-button:hover .file-icon-tooltip {
  visibility: visible;
}
@media (max-width: 575px) {
  .btn-print {
    display: none;
  }
}
