.livestock {
  background-color: white;
  height: 100%;
}
// .cel {
//   min-width: 130px;
// }
.weaningBirth,
.teamGrid {
  .row-container {
    height: calc(100vh - 200px) !important;
  }
  .sticky.accumulator {
    display: none;
    position: absolute;
    width: 100%;
    bottom: -70px !important;
  }
}

.shoppingGrid,
.salesGrid,
.confinement,
.farmDetailsGrid {
  .datagrid-general {
    transform: rotateX(180deg);
    display: flex;
    flex-direction: column-reverse;
  }

  .content-grid {
    transform: rotateX(180deg);
  }

  .sticky.accumulator {
    transform: rotateX(180deg);
  }
  .content-grid-scroll {
    height: 100%;
  }
  .row-container {
    height: calc(100% - 50px) !important;
  }
}

.confinementDetail {
  .footer-buttons {
    display: none;
  }
  .group-field {
    padding-top: 0;
  }
  label.float-label {
    font-size: 10px;
    position: absolute;
    top: -8px;
    z-index: 1;
    background-color: #fff;
    padding: 0 5px;
    color: #03adf0;
    left: 6px;
    margin: 0;
  }
  .form-group {
    position: relative;
  }
  .MuiAutocomplete-tag {
    height: 20px;
    margin-top: 8px;
  }
  .MuiChip-label {
    //font-size: 102x;
  }
}

$background-dropdown: #0c4ca3;
$background-dropdown-hover: #40b648;

.custom-dropdown-header {
  background: $background-dropdown;
  color: #fff;
  margin-bottom: 0;
  line-height: 30px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all .3s ease;

  label {
    margin: 0 !important;
    cursor: pointer;
  }

  i {
    color: $background-dropdown;
    background: #fff;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    margin-left: auto;
    border-radius: 3px;
    cursor: pointer;
  }

  &:hover {
    background-color: $background-dropdown-hover;
    i {
      color: $background-dropdown-hover;
    }
  }
}

.form-group-dropdown {
  &.out {
    display: none;
  }

  &.in {
    border: 1px solid #0c4ca3;
    margin: 0;
    padding-top: 30px;
  }
}


