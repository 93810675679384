.technical-information {
  .fixed-column {
    max-width: 400px;
    flex: 0 0 400px;
  }

  .fixed-column + .editable-grid {
    max-width: calc(100% - 400px);
    flex: 0 0 calc(100% - 400px);
  }
  &.fixedHeader .fixed-column .cel:nth-of-type(1) .float-label {
    /* background: red; */
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
