@media print {
  @page {
    size: auto;
    margin: 0mm auto;
    display: block !important; /* Not really needed in all cases */
    position: relative !important;
    width: auto !important;
    height: auto !important;
    overflow: visible !important;
  }
  .dock {
    display: none !important;
  }
  html .open-sidebar .entries-content {
    margin: 0 !important;
    overflow: unset !important;
    canvas {
      width: 100% !important;
    }
    .element-field {
      display: none;
    }
  }
  .control-pane {
    overflow: unset !important;
  }
  .dashboard-container {
    max-height: auto !important;
    overflow: initial !important;
  }
  .toolbar-dashboard {
    display: none !important;
    box-sizing: border-box !important;
  }
  .dashboard-content {
    display: block !important; /* Not really needed in all cases */
    position: relative !important;
    width: auto !important;
    height: auto !important;
  }
  body .py-5 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  #icon-sidebar-filter-dashboard {
    display: none !important;
  }
  html {
    border: none !important;
  }
  // html,
  // html body {
  //   @media (min-width: 0px) {
  //     min-width: 28cm !important;
  //     max-width: 28cm !important;
  //     width: 100% !important;
  //     max-height: 34.7cm !important;
  //     //   padding: 0.2cm;
  //     //   margin: 0.5cm 0.5cm !important;
  //     //   border: 1px #d3d3d3 solid;
  //     //   border-radius: 5px;
  //     background: white;
  //     //   box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  //     box-sizing: border-box !important;
  //   }
  // }
  //   body {
  //     width: 21cm;
  //     min-height: 29.7cm;
  //     padding: 2cm;
  //     margin: 0.5cm 0.5cm !important;
  //     border: 1px #d3d3d3 solid;
  //     border-radius: 5px;
  //     background: white;
  //     box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  //   }
}

.print {
  &.dashboard-content {
    .no-print {
      display: none;
    }
    width: 200mm;
    margin: 0 auto;
    .dashboard-container {
      overflow: unset;
    }
  }
  .toolbar-dashboard {
    display: none;
  }
}
.page-break {
  page-break-after: always;
}
.loading {
  display: none;
  &.print {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9;
    background-color: #fff;
    display: flex;
  }
}
