.makeStyles-paper-2 {
  max-width: 85%;
}
.inputControl {
  & .fixed-column {
    max-width: 60%;
    flex: 0 0 60%;
  }
  & .editable-grid {
    max-width: 40%;
    flex: 0 0 40%;
    .row-container,
    .row-grid,
    .content-grid-scroll div {
      width: 100% !important;
    }
  }
}
.inventoryValuation {
  .accumulatorCel.numericedit {
    background-color: rgb(175, 175, 175);
  }

  .accumulatorCel {
    justify-content: center !important;
  }
}
.tip-title {
  border: 1px solid #0c4ca3;
  padding: 10px;
  margin-bottom: 30px;
  border-radius: 4px;
  background: #d7e8ff;
  color: #444;
  font-size: 12px;
}
.flock-media {
  .cel.disabled-field {
    &.defaultedit {
      background-color: transparent;
    }
    background-color: #c1c1c1;
  }
}
.inputDistribution {
  h5.title {
    color: #0d4ca3;
  }
  & .fixed-column {
    max-width: 30%;
    flex: 0 0 30%;
  }
  & .editable-grid {
    max-width: 70%;
    flex: 0 0 70%;
    .row-container,
    .row-grid,
    .content-grid-scroll div {
      width: 100% !important;
      .tooltip-content {
        width: 300px !important;
      }
    }
  }
}
.livestockExepenses {
  &.livesotckStock.fixedHeader .row-container {
    height: auto !important;
  }
  & .fixed-column {
    max-width: 70%;
    flex: 0 0 70%;
  }
  & .editable-grid {
    max-width: 30%;
    flex: 0 0 30%;
    .row-container,
    .row-grid,
    .content-grid-scroll div {
      width: 100% !important;
      .tooltip-content {
        width: 300px !important;
      }
    }
  }
  .row-datagrid-8 {
    min-height: 66px;
  }
}
.totalProduction .datagrid-general {
  height: auto !important;
}
.modal-fade {
  max-width: 90%;
  padding: 15px;
  background-color: #fff;
}
.grid-modal {
  .datagrid-general {
    height: 400px !important;
  }
}
.supportExpenses {
  .datagrid-general {
    height: auto !important;
  }
}
.total-expenses {
  .datagrid-general {
    height: auto !important;
  }
  .datagrid-general {
    min-height: auto !important;
  }
  .cel.moneyedit,
  .cel.defaultedit {
    min-height: 56px;
  }
}
.stockTotal {
  display: grid;
  font-size: 12px;
  grid-template-columns: 0.7fr 0.3fr;
  &.red {
    background-color: red;
    color: #fff;
  }
  &.green {
    color: #fff;
    background-color: green;
  }
}

.stockTotal div {
  border: 1px solid #afafaf;
}

.stockTotal div:last-child {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.livestockExpensesGrid {
  .row-grid {
    border: none !important;
    &:last-child {
      .cel {
        min-height: 55px;
      }
      &:before {
        background: #b1b2b1;
        content: '';
        height: 11px;
      }
    }
  }
}
.back-button {
  height: 33px;
  padding: 5px 6px;
}
.inventoryValuation {
  .fixed-column {
    .item-col {
      &:nth-of-type(1) .cel {
        background-color: transparent !important;
      }
    }
  }
  .row-grid {
    .item-col {
      &:nth-of-type(1) .cel,
      &:nth-of-type(2) .cel,
      &:nth-of-type(3) .cel,
      &:nth-of-type(4) .cel {
        background-color: #eaeaea;
      }
      &:nth-of-type(5) .cel,
      &:nth-of-type(6) .cel,
      &:nth-of-type(7) .cel {
        background-color: #d2d0d0;
      }
      &:nth-of-type(8) .cel,
      &:nth-of-type(9) .cel,
      &:nth-of-type(10) .cel,
      &:nth-of-type(11) .cel {
        background-color: #c1c1c1;
      }
      &:nth-of-type(12) .cel {
        background-color: #9a9999;
      }
    }
    .MuiInputBase-input {
      background: #0d4ca321;
    }
    .MuiInputBase-input.Mui-disabled {
      background: #c3c3c3;
    }
  }
}
.financing {
  &.livesotckStock.fixedHeader .row-container {
    @media (max-height: 850px) {
      height: auto !important;
    }
  }
}

// .\31 -7 {
//   &:hover {
//     &:before {
//       opacity: 1;
//       visibility: visible;
//     }
//   }
//   &:before {
//     content: '';
//     opacity: 0;
//     visibility: hidden;
//     background-color: #00000061;
//     width: 100%;
//     height: 100%;
//     position: absolute;
//     z-index: 99999;
//   }
// }
// .\31 -8,
// .\31 -9,
// .\31 -10,
// .\32 -7,
// .\32 -8,
// .\32 -9,
// .\32 -10 {
// }
