.login-container {
  & * {
    z-index: 2;
  }
  background-image: url('../../assets/images/bg_login.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  &:before {
    background: linear-gradient(
      120deg,
      rgb(255, 255, 255) 10%,
      rgba(0, 121, 193, 0) 50%
    );
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    content: '';
  }
  .float-logo {
    position: absolute;
    width: 45%;
    bottom: 0px;
    right: 0;
  }
  .logo-login-page {
    max-width: 550px;
  }
  .row-logo {
    height: 100%;
    .logo-login-page {
      margin-top: 80px;
      @media (max-width: 768px) {
        margin-top: 0px;
        padding: 0 !important;
      }
    }
  }
  .container-front {
    z-index: 0;
    height: 100%;
  }
}
