.form-login {
  display: flex;
  flex-direction: column;
  grid-row-gap: 10px;
  height: 300px;
}
.title {
  font-size: 28px;
  font-weight: bold;
  line-height: 32.81px;
  color: #333333;
  
}
.subtitle-login {
  font-size: 16px;
  line-height: 22px;
  color: #757575;
  min-height: 60px;
}
input {
  border: 1px solid #eee;
  background-color: #fff !important;
  padding: 6px 15px;
  border-radius: 3px;
  margin-bottom: 10px;
  font-weight: 300;
  color: #000;
}
.linkForgotPassword {
  align-self: flex-end;
  max-width: 100%;
}
.divLogin {
  display: flex;
  flex: 1;
  align-items: flex-end;

  .btnLogin {
    flex-grow: 1; 
  }
}