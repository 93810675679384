.form-forgot-password {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 310px;
}
.title {
  font-size: 28px;
  font-weight: bold;
  line-height: 32.81px;
  color: #333333;
  
}
.subtitle-forgot-password {
  font-size: 16px;
  line-height: 22px;
  color: #757575;
  min-height: 20px;
}
input {
  border: 1px solid #eee;
  background-color: #fff !important;
  padding: 6px 15px;
  border-radius: 3px;
  font-weight: 300;
  color: #000;
}
.linkGoBackLogin {
  max-width: 100%;
}